import {ref, reactive, toRefs, computed,watch,defineComponent, onMounted, getCurrentInstance,provide,nextTick} from 'vue';
const ChannelCardHelper = defineComponent({
    name:'ChannelCard',
    title:'渠道管理',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        provide('uploadOwner', proxy);
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            disabledChannelCode:false,
            refMap:new Map(),
            showUpload:false,
            corpDesc:'',

            corpTreeData: [],
            cityData:[],
            addStatus:false,
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: false,
                modelPath: "/channel"
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                corpId: [
                    {required: true, message: "请选择机构ID", trigger: "blur" }
                ],
                type: [
                    {required: true, message: "请选择渠道类型", trigger: "blur" }
                ],
                channelCode: [
                    {required: true, message: "请输入渠道编码", trigger: "blur" }
                ],
                name: [
                    {required: true, message: "请输入渠道名称", trigger: "blur" }
                ],
                contacts: [
                    {required: true, message: "请输入联系人", trigger: "blur" }
                ],
                telephone: [
                    // {required: true, message: "请输入联系电话", trigger: "blur" }
                    { required: true,validator: utils.$$str.checkPhone, trigger: 'blur' }
                ],
                address: [
                    {required: true, message: "请输入联系地址", trigger: "blur" }
                ],
                cities: [
                    {required: true, message: "请选择城市", trigger: "blur" }
                ],
                code: [
                    {required: true, message: "请输入登录账号", trigger: "blur" }
                ],
                pwd: [
                    {required: true, message: "请输入登录密码", trigger: "blur" }
                ],
            }
        })
        onMounted(()=>{
        })
        //---------------------------computed---------------------------
        //cascader数据集获取（静态数据集可参考下方select，动态的在beforeOpen中去请求后台构建，然后赋值给dataObj中相关项）
        const cascaderData=computed(()=>{
            return (params) => {
                if(params && 'corpId'==params.comboId){
                    return dataObj.corpTreeData;
                }
            }
        })
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params) => {
                if(params && 'type'==params.comboId){
                     return [{value:0,label:'企业'},{value:1,label:'个人'}]
                }
            }
        })

        const buildUploadInitParams=computed(()=>{
            return (params) => {
                return {
                    listType:'picture-card',
                    showFileList:true,
                    showInDialog:true,
                    accept:'.jpg,.jpeg,.png,.JPG,.JPEG,.PBG',
                    uploadModule:'channel',
                    uploadType:params.uploadType
                }
            }
        })

        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue,selectId)=>{
            if('provinceId'==selectId){
                dataObj.form.cities='';
                await buildCityData(newValue);
            }else if('type'==selectId){
                dataObj.corpDesc=(newValue==0?'法人':'');
            }
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(data,addOrLoad,cardEngine)=>{
            dataObj.corpDesc=(data.data.type==0?'法人':'');
            //todo:请求后台，构造cascader数据
            dataObj.corpTreeData=await utils.$$api.corpTreeData();
            if('/add'==addOrLoad){
                dataObj.addStatus=true;
            }else{
                dataObj.disabledChannelCode=data.disabledChannelCode;
                dataObj.form.cities=data.data.cities.split(',');
                dataObj.showUpload=true;
                if(data.data.status==1 || data.data.status==2){
                    dataObj.disabled=true;
                    dataObj.refMap.get('hzxy').disableUpload(true);
                    dataObj.refMap.get('sfzFront').disableUpload(true);
                    dataObj.refMap.get('sfzBack').disableUpload(true);
                    dataObj.refMap.get('yyzz').disableUpload(true);
                }else if(data.data.status==0)dataObj.disabled=false;
            }
            utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'hzxy',files:data.data.hzxyList});
            utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'sfzFront',files:data.data.sfzFrontList});
            utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'sfzBack',files:data.data.sfzBackList});
            utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'yyzz',files:data.data.yyzzList});
            await buildCityData();
        }
        //-----------------------------upload---------------------------
        //上传的时候，增加参数传入到后台
        const buildUploadParams=(formData,params,proxy)=>{
            formData.append('belongMaxId',dataObj.form.id);
            // formData.append('belongMinId',proxy.uploadParams.uploadType);
            // formData.append('uploadModule',proxy.uploadParams.uploadModule);
        }
        //上传成功之后，需要把本次上传的图片文件追加到上传控件文件数组中，
        const afterResult=(res,uploadInst)=>{
            utils.$$lghdUtils.dealAfterUpload({res:res,uploadInst:uploadInst});
        }

        //-----------------------------upload---------------------------

        const buildCityData=async ()=>{
            dataObj.cityData = await utils.$$api.getAllCities();//市
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        const afterSaveHandler=(res,contentName,engineInst)=>{
            dataObj.disabled=false;

            dataObj.refMap.get('hzxy').disableUpload(true);
            dataObj.refMap.get('sfzFront').disableUpload(true);
            dataObj.refMap.get('sfzBack').disableUpload(true);
            dataObj.refMap.get('yyzz').disableUpload(true);
            return true;
        }

        const startHandler=async()=>{
            utils.$$lghdUtils.startHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }
        const stopHandler=async()=>{
            utils.$$lghdUtils.stopHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }

        const sassClaimHandler=async ()=>{
            await utils.$$lghdUtils.buildClaimDialog({proxy:proxy});
        }
        const sureHandler=async (modelInst)=>{
            let res=await utils.$$lghdUtils.claimDialogSure({modelInst:modelInst,proxy:proxy,url: dataObj.compParams.modelPath + "/custom",id:dataObj.form.id});
            return res;
        }
        const resetPwdHandler=async()=>{
            utils.$$tools.configBox({
                msgContent:'确定要执行该操作吗?',
                fn:async ()=> {
                    let url = dataObj.compParams.modelPath + "/custom";
                    let params={id:dataObj.form.id,operateType:'resetPwd'}
                    let res = await utils.$$api.postRequest({ url: url, params: params});
                    utils.$$tools.success({ message: res.msg });
                }
            });
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,cascaderData,beforeOpen,beforeSaveHandler,startHandler,stopHandler,
            buildUploadInitParams,afterResult,buildUploadParams,sassClaimHandler,sureHandler,resetPwdHandler,afterSaveHandler
         
        }
    }
});
export default ChannelCardHelper;